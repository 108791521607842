'use strict';

if ($("#store-result").length) {
  var renderStores = function renderStores() {
    var str_json = JSON.parse(localStorage.getItem('warehouses'));
    var items = JSON.parse(str_json);
    var warehousesSelect = "";
    warehousesSelect += "<select id='transfer_warehouse' class='form-control'>";
    warehousesSelect += "<option>-------</option>";
    for (var i = 0; i < items.length; i++) {
      if (items[i].pk === parseInt(storeId)) {
        var index = items.indexOf(items[i]);
        // items.splice(index, 1);
      }
      warehousesSelect += "<option value='" + items[i].pk + "'>";
      warehousesSelect += items[i].fields.name;
      warehousesSelect += "</option>";
    }
    warehousesSelect += "</select>";
    return warehousesSelect;
  };

  var renderProducts = function renderProducts() {
    var items = JSON.parse(localStorage.getItem('products'));
    var productsSelect = '';
    productsSelect += "<select id='debit_warehouse' class='form-control'>";
    productsSelect += "<option>-------</option>";
    for (var i = 0; i < items.length; i++) {
      productsSelect += "<option value='" + items[i].pk + "'>";
      productsSelect += items[i].name;
      productsSelect += "</option>";
    }
    productsSelect += "</select>";
    return productsSelect;
  };

  var storeId = $('#debit-btn').attr('data-store');
  $.getJSON('/warehouses/products/debit/' + storeId + '/', function (json, textStatus) {
    var products_json = json;
    localStorage.setItem('products', JSON.stringify(products_json));
  });

  $.getJSON('/warehouses/json/', function (json, textStatus) {
    var warehouses_json = json;
    localStorage.setItem('warehouses', JSON.stringify(json));
  });

  $('#debit-btn').click(function (event) {
    $(this).hide();
    $('#debit-close').show();
    renderProducts();
    $('#debit-result').show().prepend(renderProducts());
  });

  $('#debit-close').click(function (event) {
    $(this).hide();
    $('#debit-btn').show();
    $('#debit-result').hide();
    $('#debit_warehouse').remove();
  });

  $(document).on('change', '#debit_warehouse', function () {
    var pk = $(this).val();
    $.getJSON('/warehouses/products/json/' + pk + '/', function (json, textStatus) {
      var obj = JSON.parse(json.product);
      window.price = obj[0].fields.price;
    });
  });

  $('#quantity').keyup(function (event) {
    var quantity = $(this).val();
    var cPrice = parseFloat(window.price);
    $("#final_sum").val(cPrice * quantity);
  });

  $("#add_debit").click(function (event) {
    var productId = document.querySelector('#debit_warehouse').value;
    var quantity = $('#quantity').val();
    var final_sum = $('#final_sum').val();
    $.ajax({
      url: '/warehouses/add/debit/',
      type: 'POST',
      data: {
        warehouse_id: storeId,
        product_id: productId,
        quantity: quantity,
        final_sum: final_sum
      }
    }).done(function (data) {
      location.reload();
    }).fail(function () {
      location.reload();
    });
  });

  $('#transfer-btn').click(function (event) {
    $(this).hide();
    $('#transfer-close').show();
    $('#transfer-result').show().prepend(renderProducts()).prepend(renderStores());
  });

  $('#transfer-close').click(function (event) {
    $(this).hide();
    $('#transfer-btn').show();
    $('#transfer-result').hide();
    $('#transfer_warehouse').remove();
    $('#debit_warehouse').remove();
  });

  $('#add_transfer').click(function (event) {
    var product_id = $('#debit_warehouse').val();
    var warehouse_tran = $('#transfer_warehouse').val();
    $.ajax({
      url: '/warehouses/add/transfer/',
      type: 'POST',
      data: {
        warehouse_id: storeId,
        product_id: product_id,
        tws_id: warehouse_tran
      }
    }).done(function (data) {
      // console.log(data);
      location.reload();
    }).fail(function () {
      location.reload();
    });
  });
}