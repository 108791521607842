'use strict';

$(function () {

  /** Get selected Ultrasound procedures and patient id from select then redirect to add procedure */
  $('#up_select_submit').click(function (e) {
    e.preventDefault();
    var selectedUP = $('#up_select').val();
    var selectedUpPatient = $('#up_select').attr('data-patient');
    var url = '/patients/' + selectedUpPatient + '/up/procedures/' + selectedUP + '/add';
    $(location).attr('href', url);
  });
});