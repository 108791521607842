'use strict';

// users pick

if ($("#users").length) {
  var autocompleteSet = [];

  $.ajax({
    url: '/users/json/',
    type: 'GET'
  }).done(function (data) {
    localStorage.setItem('users_all', data.users);
  });
  var respData = localStorage.getItem("users_all").split(',');

  if (respData.length > 0) {
    for (var i = 0; i < respData.length; i++) {
      autocompleteSet.push({
        value: respData[i]
      });
    }
  }

  $('#id_lab_user_add').autocomplete({
    lookup: autocompleteSet
  });
}