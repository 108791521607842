'use strict';

/** Init autocomplete for patient search field   */
if ($("#patients").length) {

  $.ajax({
    url: '/patients/json/',
    type: 'GET'
  }).done(function (data) {
    var autocompleteSet = data.map(function (patient) {
      return {
        value: patient.lastname,
        data: { pk: patient.pk }
      };
    });

    $('#autocomplete_patients').autocomplete({
      lookup: autocompleteSet
    });
  });
}