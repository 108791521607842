"use strict";

$("#id_claim_date").datepicker();
$("#id_expiration_date").datepicker({
  dateFormat: 'dd/mm/yy'
});
$("#id_invoice_date").datepicker({
  dateFormat: 'dd/mm/yy'
});

$(function () {
  var dateFormat = "yy-mm-dd",
      from = $("#from_request_date").datepicker({
    dateFormat: dateFormat,
    defaultDate: "+1d",
    changeMonth: true,
    numberOfMonths: 1
  }).on("change", function () {
    to.datepicker("option", "minDate", getDate(this));
  }),
      to = $("#to_request_date").datepicker({
    defaultDate: "+1d",
    changeMonth: true,
    numberOfMonths: 1,
    dateFormat: dateFormat
  }).on("change", function () {
    from.datepicker("option", "maxDate", getDate(this));
  });

  function getDate(element) {
    var date;
    try {
      date = $.datepicker.parseDate(dateFormat, element.value);
    } catch (error) {
      date = null;
    }
    return date;
  }
});