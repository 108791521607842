"use strict";

$(function () {

  $('#id_conclusion').change(function () {
    var id = $(this).val();
    if (id == "Тип 2. Запальний тип мазка.") {
      $("#type2_analysis").show();
      $("#type3_analysis").hide();
    }
    if (id == "Тип 3.") {
      $("#type2_analysis").hide();
      $("#type3_analysis").show();
    }
    if (id != "Тип 3." && id != "Тип 2. Запальний тип мазка.") {
      $("#type2_analysis").hide();
      $("#type3_analysis").hide();
    }
  });
  $.datepicker.regional['uk'] = {
    closeText: "Закрити",
    prevText: "&#x3C;",
    nextText: "&#x3E;",
    currentText: "Сьогодні",
    monthNames: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
    monthNamesShort: ["Січ", "Лют", "Бер", "Кві", "Тра", "Чер", "Лип", "Сер", "Вер", "Жов", "Лис", "Гру"],
    dayNames: ["неділя", "понеділок", "вівторок", "середа", "четвер", "п’ятниця", "субота"],
    dayNamesShort: ["нед", "пнд", "вів", "срд", "чтв", "птн", "сбт"],
    dayNamesMin: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    weekHeader: "Тиж",
    dateFormat: "mm/dd/yy",
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ""
  };
  $.datepicker.setDefaults($.datepicker.regional['uk']);
  // $( "#id_date_birthday" ).datepicker({
  // 	changeYear: true,
  // 	yearRange: '1920:2020',
  // });

  $("#id_date_incoming").datepicker({
    dateFormat: "dd.mm.yy",
    changeYear: true,
    yearRange: '1990:2030'
  });

  // you can use this class for ua date input
  $('.ua-date-input').datepicker({
    dateFormat: "dd.mm.yy"
  });

  // new standart. us it ever possible
  $('.ua-date-input-new').datepicker({
    dateFormat: "dd/mm/yy"
  });

  //you can use class date-input if you want datepicker
  $(".date-input").datepicker();

  $("#appointment_date").datepicker();
  $("#id_search_date").datepicker();
  $("#id_hepatitis_date").datepicker();
  //	$( "#id_claim_date" ).datepicker();
  $("#id_pediculosis_date").datepicker();
  $("#id_expiration_date").datepicker();
  $("#id_scabies_date").datepicker();
  $("#id_date").datepicker();
  $("#id_date_analysis").datepicker();
  $("#id_HIV_date").datepicker();
  $("#id_give_date").datepicker();
  $(".form-hospitalization p input").addClass('form-control');
  $(".form-hospitalization p select").addClass('form-control');
});

$('div.analysis input[type=text]').addClass('form-control');
$('div.analysis input[type=number]').addClass('form-control');
$('div.analysis input[type=email]').addClass('form-control');
$('div.analysis input[type=password]').addClass('form-control');
$('div.analysis select').addClass('form-control');

//Analyzes
$('#analyz_select').change(function (event) {
  var number = $(this).val();
  var id = $(this)[0].dataset.patient;
  $('#link_to_analysis').attr('href', '/analysis/' + number + '/' + id);
});

$('#submit').submit(function () {
  var res = confirm('Ви впевнені в коректності занесених даних в аналіз пацієнта?');
  if (!res) {
    return false;
  } else {
    //submits form
  }
});

//-- Acceptance --
$("#id_product").change(function (event) {
  var pk = $(this).val();
  $.getJSON('/warehouses/products/json/' + pk + '/', function (json, textStatus) {
    var obj = JSON.parse(json.product);
    window.price = obj[0].fields.price;
  });
});

$('#id_quantity').keyup(function (event) {
  var quantity = $(this).val();
  var cPrice = parseFloat(window.price);
  $("#id_final_sum").val(cPrice * quantity);
});

// Date on patient

var d = new Date();
var month = new Array("Січня", "Лютого", "Березня", "Квітня", "Травня", "Червня", "Липня", "Серпня", "Вересня", "Жовтня", "Листопада", "Грудня");
$('#date_patient_print').append(d.getDate() + " " + month[d.getMonth()] + " " + d.getFullYear() + " р.");

/****appointments_report START****/
window.onload = function () {
  document.getElementById('check1').checked = false;
};

function show_hide_empty_cells(checked) {
  var empty_row = document.getElementsByName('empty_row');
  var empty_td = document.getElementsByName('empty_td');
  var empty_date = document.getElementsByName('empty_date');
  if (checked) {
    for (var i = 0; i < empty_row.length; i++) {
      empty_row[i].setAttribute("class", "empty_on");
    }
    for (var i = 0; i < empty_td.length; i++) {
      empty_td[i].setAttribute("class", "empty_on");
    }
    for (var i = 0; i < empty_date.length; i++) {
      empty_date[i].setAttribute("class", "empty_on vert");
    }
  } else {
    for (var i = 0; i < empty_row.length; i++) {
      empty_row[i].setAttribute("class", "empty_row_off");
    }
    for (var i = 0; i < empty_td.length; i++) {
      empty_td[i].setAttribute("class", "empty_td_off");
    }
    for (var i = 0; i < empty_date.length; i++) {
      empty_date[i].setAttribute("class", "empty_td_off vert");
    }
  }
}

/****appointments_report END****/

/****work with not print in analyses START****/

/** Return parent tr by given child element */
function getTrByGivenChild(elem) {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches("tr")) return elem;
  }
  return null;
}

/** Searching for children input[type=checkbox] for given element  */
function getCheckboxByGivenParent(elem) {
  return elem.querySelector('input[type="checkbox"]');
}

/** Toggles between print and not print state for one row */
function toggleNotPrintForOneRow(elem) {
  var tableRow = getTrByGivenChild(elem);
  var checkbox = getCheckboxByGivenParent(tableRow);
  if (checkbox.checked) {
    tableRow.setAttribute('data-old', elem.className);
    tableRow.className += ' small_visibility not_print';
  } else {
    tableRow.setAttribute('class', tableRow.getAttribute('data-old'));
  }
}

/** Disables all the empty fields to not print */
function turn_off_null_rows(rootCheckBox) {
  var rows = document.getElementsByClassName('chck_null');

  Array.from(rows).forEach(function (row) {
    var rowContent = row.textContent.trim();

    if (rowContent == null || +rowContent.replace(",", ".") == 0 || rowContent == '-') {
      if (rootCheckBox.checked) {
        getCheckboxByGivenParent(getTrByGivenChild(row)).checked = true;
        getCheckboxByGivenParent(getTrByGivenChild(row)).onchange();
      } else {
        getCheckboxByGivenParent(getTrByGivenChild(row)).checked = false;
        getCheckboxByGivenParent(getTrByGivenChild(row)).onchange();
      }
    }
  });
}

/** Add not print checkboxes to all of table rows in analyzes when document loads. */
window.onload = function () {
  var table = document.getElementsByClassName('disable_tr_table')[0];

  if (table == null) return;

  var rows = table.getElementsByTagName('tr');
  for (var i = 0; i < rows.length; i++) {
    var td = document.createElement('td');
    td.className = 'not_print';
    // language=HTML
    td.innerHTML = "\n            <div class=\"not_print div_hide\">\n                <input type=\"checkbox\" id=\"check" + i + "\" onchange=\"toggleNotPrintForOneRow(this);\">\n                <label for=\"check" + i + "\">\u041D\u0435 \u0434\u0440\u0443\u043A\u0443\u0432\u0430\u0442\u0438</label>\n            </div>";
    rows[i].appendChild(td);
  }
};

/****work with not print in analyses END****/

/****disable input(number) key up and down START****/
$(document).ready(function () {
  $("input[type=number]").on("focus", function () {
    $(this).on("keydown", function (event) {
      if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault();
      }
    });
  });
});
/****disable input(number) key up and down  END****/